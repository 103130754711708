<template>

    <section>
        <Header/>
        <section class="main">
          <Feedback />

            <section class="topBar" style="background-color:#1B355E;margin-top:0;color:white;text-shadow: 0px 3px 6px #00000091;margin-bottom:0px;">
                <h1 class="about_maintitle">{{ content[this.setLang].title}}</h1>
            </section>

            <section>

              <div class="introText">

                <div class="container">
                  <div class="row">
                    <div class="col-md-9">
                      <!-- Material form contact -->

                      <form novalidate class="md-layout" @submit.prevent="validateContact">
                        <md-card style="border:solid 1px #E7401C;box-shadow:none;color:#3C4148;" class="md-layout-item md-size-100 md-small-size-100 p-5">

                          <md-card-content>
                            <div class="md-layout md-gutter">
                              <div class="md-layout-item md-small-size-100">
								<md-field :class="getValidationClass('firstName')">
                                  <label for="first-name">{{ content[this.setLang].first_name}}</label>
                                  <md-input required name="first-name" id="first-name" autocomplete="given-name" v-model="form.firstName" :disabled="sending" />
									<span class="md-error" v-if="!$v.form.firstName.required">{{ content[this.setLang].error_field_required}}</span>
									<span class="md-error" v-else-if="!$v.form.firstName.minlength">{{ content[this.setLang].error_field_required}}</span>                                  
                                </md-field>
                              </div>
                            </div>

                            <div class="md-layout md-gutter">
                              <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('lastName')">
                                  <label for="last-name">{{ content[this.setLang].last_name}}</label>
                                  <md-input required name="last-name" id="last-name" autocomplete="given-name" v-model="form.lastName" :disabled="sending" />
									<span class="md-error" v-if="!$v.form.lastName.required">{{ content[this.setLang].error_field_required}}</span>
									<span class="md-error" v-else-if="!$v.form.lastName.minlength">{{ content[this.setLang].error_field_required}}</span>                                  
                                </md-field>
                              </div>
                            </div>

                            <div class="md-layout md-gutter">
                              <div class="md-layout-item md-small-size-100">
                                <md-field >
                                  <label for="organization">{{ content[this.setLang].organization}}</label>
                                  <md-input name="organization" id="organization" autocomplete="" v-model="form.organization" :disabled="sending" />
                                </md-field>
                              </div>
                            </div>

                            <div class="md-layout md-gutter">
                              <div class="md-layout-item md-small-size-100">
                                <md-field >
                                  <label for="job-title">{{ content[this.setLang].job_title}}</label>
                                  <md-input name="job-title" id="job-title" autocomplete="" v-model="form.jobTitle" :disabled="sending" />
                                </md-field>
                              </div>
                            </div>

                            <div class="md-layout md-gutter">
                              <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('email')">
                                  <label for="email">{{ content[this.setLang].email}}</label>
                                  <md-input required name="email" id="email" autocomplete="" v-model="form.email" :disabled="sending" />
                                      <span class="md-error" v-if="!$v.form.email.required">{{ content[this.setLang].error_field_required}}</span>
                                      <span class="md-error" v-else-if="!$v.form.email.email">{{ content[this.setLang].error_email_invalid}}</span>
                                </md-field>
                              </div>
                            </div>

                            <div class="md-layout md-gutter">
                              <div class="md-layout-item md-small-size-100">
                                 <md-field >
                                  <label for="phone">{{ content[this.setLang].phone}}</label>
                                  <md-input name="phone" id="phone" autocomplete="" v-model="form.phone" :disabled="sending" />
                                </md-field>
                              </div>
                            </div>

                            <div class="md-layout md-gutter">
                              <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('subject')">
                                  <label for="subject">{{ content[this.setLang].subject}}</label>
                                  <md-input required name="subject" id="subject" autocomplete="" v-model="form.subject" :disabled="sending" />
                                      <span class="md-error" v-if="!$v.form.subject.required">{{ content[this.setLang].error_field_required}}</span>
                                      <span class="md-error" v-else-if="!$v.form.subject.maxLength">{{ content[this.setLang].error_field_required}}</span>
                                </md-field>
                              </div>
                            </div>

                            <div class="md-layout md-gutter">
                              <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('message')">
                                  <label for="message">{{ content[this.setLang].message}}</label>
                                  <md-textarea required name="message" id="message" autocomplete="" v-model="form.message" :disabled="sending" md-autogrow md-counter="400"></md-textarea>
                                  <span class="md-error" v-if="!$v.form.message.required">{{ content[this.setLang].error_field_required}}</span>
                                  <span class="md-error" v-else-if="!$v.form.message.maxLength">{{ content[this.setLang].error_field_required}}</span>
                                </md-field>
                              </div>
                            </div>
                            
                            <button class="downloadBtn" type="submit" :disabled="submitStatus === 'PENDING'">{{content[this.setLang].submit}}</button>
							<br/><br/><br/>
                            <p class="md-error" v-if="submitStatus === 'OK'">{{ content[this.setLang].text_thank_you}}</p>
                            <p class="md-error" v-if="submitStatus === 'PENDING'">Sending...</p>              

                          </md-card-content>
                        </md-card>
                      </form>
                    </div>
                    <div class="col-md-3">
                      <p v-html="content[this.setLang].side_bar"></p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

        </section>
        <Footer/>
    </section>
</template>

<script>
// Imports
import Vue from 'vue'
import VueHead from 'vue-head'
import Header from './Header.vue'
import Footer from './Footer.vue'
import Feedback from '../components/feedback.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueHead, VueAxios, axios)

// VueMaterial
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
Vue.use(VueMaterial)

// Vuelidate
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);
import required from 'vuelidate/lib/validators/required';
import minLength from 'vuelidate/lib/validators/minLength';
import email from 'vuelidate/lib/validators/email';
import maxLength from 'vuelidate/lib/validators/maxLength';
  
export default {
  components: {
    Header,
    Footer,
    Feedback
  },
  data() {
    return {
      submitStatus: null,
      sending: false,
      form: {
        firstName: null,
        lastName: null,
        organization: null,
        jobTitle: null,
        email: null,
        phone: null,
        subject: null,
        message: null
      },
      content:{
        en: {
          title: 'Contact Us',
          first_name : 'First Name',
          last_name: 'Last Name',
          organization: 'Organization (Optional)',
          job_title: 'Job Title (Optional)',
          email: 'Email',
          phone: 'Phone (Optional)',
          subject: 'Subject',
          message: 'Message',
          submit: 'Submit',
          side_bar: 'Contact us if you would like further information. <br><br> <b>Occupational Cancer Research Centre</b> <br><br> 505 University Avenue, <br>14th Floor Toronto, <br>Ontario, M5G 1X3 <br>Canada  <br><br> T: 416-217-1849 <br>F: 416-971-6888',
          error_field_required: 'This field is required.',
          error_email_invalid: 'Something’s not right with your email address.',
          text_thank_you: 'Thank you for your submission.'

        },
        fr: {
          title: 'Contactez-nous',
          first_name : 'Prénom',
          last_name: 'Nom de la famille',
          organization: 'Organisation (Optionnel)',
          job_title: 'Nom de poste (Optionnel)',
          email: 'Courriel',
          phone: 'Téléphone (Optionnel)',
          subject: 'Sujet',
          message: 'Message',
          submit: 'Soumettre',
          side_bar: 'N’hésitez pas à communiquer avec nous si vous souhaitez recevoir de plus amples renseignements. <br><br> <b>Occupational Cancer Research Centre</b> <br><br> 505, avenue University <br>14e étage <br>Toronto (Ontario) M5G 1X3 <br>Canada  <br><br> T: 416-217-1849 <br>F: 416-971-6888',
          error_field_required: 'Ce champ est requis.',
          error_email_invalid: 'Quelque chose ne va pas avec votre adresse courriel.',
          text_thank_you: 'Merci.'
        }
      }
    }
  },
  head: {
    title:{
      inner: 'Contact Us | Ontario Occupational Disease Statistics'
    }
  },
  computed:{
    setLang : function () {
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      return lang;
    }
  },
	validations: {
		form: {
			firstName: {
				required,
				minLength: minLength(3)
			},
			lastName: {
				required,
				minLength: minLength(3)
			},
			email: {
				required,
				email
			},
			subject: {
				required,
				maxLength: maxLength(1024)
			},
			message: {
				required,
				maxLength: maxLength(1024)
			}
		}
	},
	
  methods: {
	resetForm() {
		console.log('Reseting the form');
		var self = this; //you need this because *this* will refer to Object.keys below`
		this.submitStatus = null,
		this.sending = false,
		//Iterate through each object field, key is name of the object field`
		Object.keys(this.form).forEach(function(key) {
			self.form[key] = '';
		});
	},
	
	// Send post
	makePostRequest() {
	
		var postData = {
				firstName: this.form.firstName,
				lastName: this.form.lastName,
				organization: this.form.organization,
				jobTitle: this.form.jobTitle,
				email: this.form.email,
				phone: this.form.phone,
				subject: this.form.subject,
				message: this.form.message,
		};
		
		//placeholder axiosConfig
		var axiosConfig = { headers: { }}; 
		this.submitStatus = 'PENDING';
		//test url - 'http://localhost:8080/api/data.php?action=sendMail'
		//staging url - /php/data.php?action=sendMail
		var res = axios.post('/php/data.php?action=sendMail', postData, axiosConfig);
		res.then(response => {
					console.log(response);
					this.submitStatus = 'OK';
					// remove vlidate error message
					this.$v.$reset();
					// reset form 
					this.resetForm();
				});
		setTimeout(() => { this.submitStatus = 'OK'; }, 500);
	},
	
	//form validation
	validateContact () {
		this.$v.$touch();
		//console.log(this.$v.$invalid);
		if (!this.$v.$invalid) {
			this.makePostRequest();
			//this.sendMail();
		} else {
			this.submitStatus = 'ERROR';
		}
	},
	// field validation
	getValidationClass (fieldName) {
		const field = this.$v.form[fieldName];
		if (field) {
			return {
				'md-invalid': field.$invalid && field.$dirty
			}
		}
	},
	// send form to email
    sendMail() {
      // sanitize/validation
      // should these be entered into the database or just emailed?
      var f = this.form.firstName;
      var l = this.form.lastName;
      var o = this.form.organization;
      var j = this.form.jobTitle;
      var e = this.form.email;
      var p = this.form.phone;
      var s = this.form.subject;
      var m = this.form.message;
      var urloc = window.location.origin;
      axios.post(urloc+"/php/data.php?action=sendMail&f="+f+"&l="+l+"&o="+o+"&j="+j+"&e="+e+"&p="+p+"&s="+s+"&m="+m+"").then(response => {
          // send email - if success display message
          console.log(response);
          return response;
      });
      console.log("send mail here check");
    }
  }
  
  
}
</script>


<style scoped>
h2 {
  font-size:32px;
  padding-bottom:30px;
}

p {
  font-size:0.7em;
  padding-bottom:50px;
}

.md-input, .md-textarea{
  border-bottom:1px solid #3C4148;
}
.md-focused > .md-input{
  border-bottom:2px solid #E7401C;
}

.md-error{
  color: #E7401C;
}

.downloadBtn {
  -webkit-border-radius: 10px;
  -moz-border-radius:10px;
  border-radius: 10px;
  color: #FFFFFF;
  font-weight:bold;
  background-color: #E7401C;
  padding:8px;
  float:right;
  cursor: pointer;
  text-align: center;
  clear:left;
  font-size:1em;
  width:50%;
}
</style>